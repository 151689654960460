/* ./styles/globals.css */

/*  Text */
.paragraph {
    @apply font-light tracking-normal text-gray-500
}

@tailwind base;
@tailwind components;
@tailwind utilities;


/* Buttons */
.button-sm {
    @apply inline-block w-full rounded-md bg-black py-4 px-8 font-medium text-white;
}
input[type='text']:focus { box-shadow: none; }
.button-sm.disabled {
    @apply cursor-not-allowed bg-gray-800 text-white;
}

.button-sm.icon {
    @apply flex items-center;
}

.button-xs {
    @apply inline-block rounded-md bg-slate-900 py-2.5 px-5 text-sm font-semibold text-white;
}

.button-xs.disabled {
    @apply cursor-not-allowed bg-gray-800 text-white;
}

.button-xs.icon {
    @apply flex items-center justify-center;
}

/* Loading States */

.loading-select > div > div {
    @apply animate-pulse rounded-t-none bg-gray-300;
}

/* Other */

.package.active {
    @apply border-gray-600;
}

.h-40vh {
    height: 40vh;
}

.active-circle {
    @apply border border-slate-800 bg-slate-900  px-5 text-white;
}

.no-scroll-bar::-webkit-scrollbar {
    display: none;
}

.PhoneInputInput:focus {
    @apply ring-0;
}

.PhoneInputInput {
    @apply w-full border-none bg-white p-1;
}

.helper-active .blink.hidden {
    @apply flex;
}

.swiper-button-next,
.swiper-button-prev {
    transform: scale(0.5);
}

.Select.is-open {
    position: relative;
    z-index: 1000;
}

b {
    @apply font-semibold;
}
